import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { memo } from 'react';
import { hideScrollbarY } from '../../../utils/cssStyles';
import { isAuthorizedList } from '../../../utils/isAuthorisedSidebarItem';
import NavList from './NavList';
// ----------------------------------------------------------------------
function NavSectionHorizontal({ data, sx, ...other }) {
    return (_jsx(Stack, { direction: "row", spacing: 1, sx: {
            mx: 'auto',
            ...hideScrollbarY,
            ...sx,
        }, ...other, children: data.map((group) => (_jsx(Items, { items: isAuthorizedList(group.items) }, group.subheader))) }));
}
export default memo(NavSectionHorizontal);
function Items({ items }) {
    return (_jsx(_Fragment, { children: isAuthorizedList(items).map((list) => (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list.title + list.path))) }));
}
